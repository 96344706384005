import React, { useState, useEffect, useContext } from 'react';
import * as S from './ExtraObjectsContainer.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../context/FunnelContext';

const ExtraObjectsContainer = props => {
  const { propertyKey, propertyValue, children, attributes } = props;

  const { extraObjects } = useContext(FunnelContext);

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked) {
      const checkProperty = () => {
        let newVal = show;
        if (Object.keys(extraObjects).includes(propertyKey)) {
          if (extraObjects[propertyKey] === propertyValue) {
            newVal = true;
          } else {
            newVal = false;
          }
        } else {
          newVal = false;
        }
        if (newVal !== show) {
          setTimeout(() => {
            setShow(newVal);
          }, 100);
        }
      };
      setInterval(() => {
        checkProperty();
      }, 200);
      return () => setChecked(true);
    }
  }, [
    extraObjects,
    show,
    setShow,
    propertyKey,
    propertyValue,
    checked,
    setChecked
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return (
      <S.Container
        {...attributes}
        layerId={`${propertyKey}=${propertyValue}`}
        editing={Builder.isEditing}
      >
        {children || noChildren()}
      </S.Container>
    );
  };

  if (show || Builder.isEditing) {
    return <Container />;
  } else {
    return ``;
  }
};

export default ExtraObjectsContainer;
